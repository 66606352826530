<template>
  <div class="">

    <v-card>
      <v-card-title>
        ONE-SHOTS
      </v-card-title>
      <v-row no-gutters v-if="loader.catalog">
        <v-col
          v-for="n in 6"
          :key="'new-'+n"
          class="pa-1 pa-lg-3 pa-md-3"
          cols="4"
          lg="3"
          md="3"
        >
          <v-sheet
            :color="`grey darken-2 `"
            width="100%!important"
          >
            <v-skeleton-loader
              width="100%!important"
              class="d-inline-block mr-lg-2 ml-lg-2 mr-sm-1 ml-sm-1 mt-3"
              type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col
          v-for="item in ll.listEntity"
          :key="'os-'+item.id"
          class="pa-1 pa-lg-3 pa-md-3"
          cols="4"
          lg="3"
          md="3"
        >
          <v-card
            outlined :to="`/one-shot/${item.slug}/`+item.id"
            min-width="108"
            class="ma-1"
          >
            <v-img
              class="white--text align-end"
              :src="item.cover_src"
              :lazy-src="require('@/assets/images/holder.jpg')"
              gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.5)"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0 "
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-card-title style="color: whitesmoke !important;"
                            class="title-inline white--text pa-1 text-white">
                <small class="text-subtitle-2">
                  <v-icon small color="white">{{ icons.mdiEyeOutline }}</v-icon>
                  {{ item.nbviews }}</small>
                <v-spacer></v-spacer>
                <!--                    <span class="font-secondary text-h5">#{{ item.number }}</span>-->
              </v-card-title>
            </v-img>
          </v-card>

        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import Banner from '@/components/Banner'
import ChapterListItemComponent from "@/components/comicbook/ChapterListItemComponent.vue";

export default {
  components: {ChapterListItemComponent, Banner},
  setup() {
    const $vuetify = getVuetify()
    const items = ref([])
    const drawer = ref(false)
    const tab = ref('cb')
    const loader = ref({
      catalog: true,
      reload: false,
    })
    const ll = ref({listEntity: []})
    const columns = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 150
      }
      if ($vuetify.breakpoint.xs) {
        return 108
      }

      return 210
    })
    const init = () => {
      Drequest.api('lazyloading.chapter?dfilters=on&next=1&per_page=9&status:eq=1&comicbook_id:isNull=1&dsort=id desc')
        .get(response => {
          console.log(response)
          loader.value.catalog = false
          ll.value = response
        })
    }
    init()
    const detail = cb => {
      drawer.value = true
      comicbook.value = cb
    }

    return {
      detail,

      tab,
      items,
      ll,
      columns,
      drawer,
      loader,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
